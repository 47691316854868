import React from "react";
import ContentHead from "./ContentHead";

export default () => (
  <div className="px-4 pt-4 pb-2">
    <ContentHead title="Personal projects" />
    <div className="mt-3">
      <ProjectItem
        icon="fab fa-react"
        title="uidrips"
        description="uidrips is a simple website where designers can share their design resources."
        link="https://uidrips.com"
        github=""
        tags={["react", "gatsby", "mdx", "netlify"]}
      />
      <ProjectItem
        icon="fab fa-react"
        title="Web Stack Hunt"
        description="Webstackhunt is an app that helps users get the technologies behind a website."
        // link="https://webstackhunt.com"
        github="https://github.com/layalii/WebStackHunt"
        tags={["react", "gatsby", "netlify"]}
      />
      <ProjectItem
        icon="fab fa-node-js"
        title="Web Stack Hunt - API"
        description="Webstackhunt is an app that helps users get the technologies behind a website."
        link=""
        github="https://github.com/layalii/wsh-api"
        tags={["node", "mongo", "express", "mongo atlas", "heroku"]}
      />
      <ProjectItem
        icon="fab fa-app-store"
        title="Expenses Mobile App"
        description="A simple mobile app to track personal finances and expenses."
        link=""
        github="https://github.com/layalii/expenses-rn-app"
        tags={["react native", "redux", "react navigation"]}
      />
      <ProjectItem
        icon="fab fa-node-js"
        title="Expenses Mobile App - API"
        description="A simple mobile app to track personal finances and expenses."
        link=""
        github="https://github.com/layalii/expenseApp-Backend"
        tags={["node", "mongo", "express", "passport"]}
        last
      />
    </div>
  </div>
);

const ProjectItem = ({
  icon,
  title,
  description,
  tags,
  link,
  github,
  last
}) => (
  <div
    className="d-flex mb-4"
    style={
      {
        // borderLeft: `3px solid red`
      }
    }
  >
    <i className={`${icon} fa-2x text-gray-500 mr-3`} />
    <div className={`${last ? "" : "border-bottom"} pb-4 w-100`}>
      <div className="d-flex align-items-center mb-2 flex-wrap">
        <h5 className="m-0 mr-2 flex-shrink-0">{title}:</h5>
        {link && (
          <a className="mr-2" href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
        {github && link && " / "}
        {github && (
          <a className="ml-2 mr-2" href={github} target="_blank"rel="noopener noreferrer">
            {github}
          </a>
        )}
      </div>
      <p className="mb-2 text-gray-600">{description}</p>
      {tags.map(t => (
        <span className="px-2 py-1 rounded-sm mr-2 small border">{t}</span>
      ))}
    </div>
  </div>
);
