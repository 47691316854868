import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './styles/index.css';
import './styles/init.css';

import Sidebar from './Sidebar';
import About from './About';
import Education from './Education';
import Projects from './Projects';
import Whatido from './Whatido';
import Blogs from './blogs';
import Interests from './Interests';
import TechnologyTags from './TechnologyTags';

import Blog1 from './Blogs/blog1';
import Blog2 from './Blogs/blog2';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('UA-151947049-1');
    ReactGA.pageview('Home');
  });

  return (
    <div className="py-5">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col-12 col-lg-4 col-xl-3 mb-5 mb-xl-0">
            <Sidebar />
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/blog/concurent-mode" component={Blog1} />
                  <Route path="/blog/server-components" component={Blog2} />
                </Switch>
              </BrowserRouter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <About />
      <Whatido />
      <Blogs/>
      <Education />
      <Projects />
      <TechnologyTags />
      <Interests />
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
