import React from "react";
import ContentHead from "./ContentHead";

export default () => (
  <div className="bg-light pt-4 px-4">
    <ContentHead title="What I do" />
    <div className="row">
      <div className="col-12 col-xl-6">
        <WhatidoCard
          icon="fab fa-react text-info"
          title="Frontend"
          description="The javascript is my go-to language. I enjoy working with it, especially using the ES2016 and frameworks like React (hooks, context .. ) ."
        />
      </div>
      <div className="col-12 col-xl-6">
        <WhatidoCard
          icon="fab fa-node-js text-info"
          title="Backend"
          description="I use Node.js, ExpressJS and MongoDB on the backend. These technologies are the back bone of the most of my projects."
        />
      </div>
      <div className="col-12 col-xl-6">
        <WhatidoCard
          icon="fab fa-app-store text-info"
          title="Mobile"
          description="Since i already work with React on a daily basis, most of my mobile app projects are based on React Native framework."
        />
      </div>
      <div className="col-12 col-xl-6">
        <WhatidoCard
          icon="fas fa-chart-bar text-info"
          title="Analytics"
          description="I have an experience working with analytics and tracking tools such as Google Analytics, Amplitude and Segment."
        />
      </div>
    </div>
  </div>
);

const WhatidoCard = ({ title, description, icon }) => (
  <div className="rounded shadow-sm p-4 bg-white mb-4 d-flex">
    <i className={`${icon} fa-2x mr-4`} />
    <div>
      <h6 className="text-gray-700">{title}</h6>
      <p className="m-0 text-gray-500">{description}</p>
    </div>
  </div>
);
