import React from "react";
import ContentHead from "./ContentHead";

export default () => (
  <div className="bg-light pt-4 pb-2 px-4">
    <ContentHead title="Technologies" />
    <div className="d-flex flex-wrap mb-5">
      <TechTag title="HTML5" />
      <TechTag title="CSS3" />
      <TechTag title="Flexbox" />
      <TechTag title="JavaScript" />
      <TechTag title="ES2016" />
      <TechTag title="React" />
      <TechTag title="React Native" />
      <TechTag title="React Router" />
      <TechTag title="React Navigation" />
      <TechTag title="Redux" />
      <TechTag title="Hooks" />
      <TechTag title="NodeJS" />
      <TechTag title="Mongodb" />
      <TechTag title="Express" />
      <TechTag title="CDN" />
      <TechTag title="Styled Components" />
      <TechTag title="Gatsby" />
      <TechTag title="Bootstrap" />
      <TechTag title="TailwindCSS" />
      <TechTag title="Google Analytics" />
      <TechTag title="Segment" />
      <TechTag title="Sketch" />
      <TechTag title="Netlify" />
      <TechTag title="Logrocket" />
      <TechTag title="Sentry" />
      
    </div>
    <ContentHead title="Collaboration tools" />
    <div className="d-flex flex-wrap">
      <TechTag title="Github" />
      <TechTag title="Slack" />
      <TechTag title="Linear" />
      <TechTag title="Notion" />
    </div>
  </div>
);

const THEMES = [
  "text-white bg-gray-500"
  // "text-white bg-info",
  // "text-white bg-gray-700",
  // "text-primary bg-white",
  // "text-gray-700 bg-white"
];

const TechTag = ({ title }) => {
  const theme = Math.round(Math.random() * (THEMES.length - 1));
  return (
    <span className={`small px-2 py-1 rounded mr-2 mb-2 ${THEMES[theme]}`}>
      {title}
    </span>
  );
};
