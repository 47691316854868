import React from "react";

export default () => (
  <div className="d-flex justify-content-center">
    <a href='/'>
    <div
      className="p-1 bg-white shadow-sm rounded-circle d-inline-block"
      style={{
        marginTop: -70
      }}
    >
      <div
        className="avatar avatar-lg"
        style={{
          backgroundImage: `url(https://i.ibb.co/jrXMC4p/laila-Elmansour.jpg)`,
          width: 140,
          height: 140
        }}
      />
    </div>
    </a>
  </div>
);